import { styled } from '@mui/system'
import { colors, colorsMap, fontSizes } from '../consts'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

type ButtonStyleProps = {
  buttonType?: 'primary' | 'secondary' | 'outlined'
  disabled?: boolean
  uiTheme?: 'light' | 'dark' | 'gray'
}

export const PrimaryBtn = styled('button')<ButtonStyleProps>(
  ({ theme, disabled = false, uiTheme = 'dark' }) => ({
    display: 'inline-block',
    padding: theme.spacing(1.5, 3),
    borderRadius: theme.spacing(1),
    border: 'none',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: colors.black,
    backgroundColor: getBackgroundColor(disabled, uiTheme),
    cursor: disabled ? 'not-allowed' : 'pointer',
    '&:hover': {
      backgroundColor: disabled ? getBackgroundColor(disabled, uiTheme) : colors.blue,
      cursor: disabled ? 'not-allowed' : 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '1rem',
      padding: theme.spacing(0.5, 1),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      padding: theme.spacing(0.5),
    },
  })
)

export const SecondaryBtn = styled('button')<ButtonStyleProps>(
  ({ theme, disabled = false, uiTheme = 'dark' }) => ({
    display: 'inline-block',
    padding: theme.spacing(1.5, 3),
    borderRadius: theme.spacing(1),
    border: 'none',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: colors.black,
    backgroundColor: disabled
      ? getBackgroundColor(disabled, uiTheme)
      : colors.lightBlue,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.blue,
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '1rem',
      padding: theme.spacing(0.5, 1),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      padding: theme.spacing(0.5),
    },
  })
)

export const OutlinedBtn = styled('button')<ButtonStyleProps>(
  ({ theme, disabled = false }) => ({
    display: 'inline-block',
    padding: theme.spacing(1.5, 3),
    borderRadius: theme.spacing(1),
    border: `solid 1px ${colors.text}`,
    fontSize: '1rem',
    fontWeight: 'bold',
    color: colors.text,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '1rem',
      padding: theme.spacing(0.5, 1),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      padding: theme.spacing(0.5),
    },
  })
)

type ShareStyleProps = {
  size: string
  border: boolean
  sTheme?: 'light' | 'dark'
  direction?: 'row' | 'column'
}

export const ShareBtnCon = styled('div')<ShareStyleProps>(
  ({ sTheme, size, border, direction, theme }) => ({
    display: 'flex',
    flexDirection: direction || 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1, 2),
    cursor: 'pointer',
    border: border ? `1px solid ${colors.lightestGrey}` : 'none',
    borderRadius: 12,
    padding: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0.5, 1),
    },
    '& .icon': {
      width: size === 'lg' ? 30 : 20,
      color: sTheme === 'dark' ? colors.white : colors.text,
      marginBottom: 7,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 5,
      },
    },
    '& .label': {
      color: sTheme === 'dark' ? colors.white : colors.text,
      margin: theme.spacing(0.25, 1.5),
      fontSize: size === 'lg' ? fontSizes.medium : '0.8rem',
      fontWeight: 600,
      maxWidth: size === 'lg' ? 130 : 100,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: size === 'lg' ? fontSizes.medium : '0.6rem',
        margin: theme.spacing(0.25, 1),
      },
    },
  })
)

type ArrowStyleProps = {
  arrowColor?: string
}

export const RightArrow = styled(
  KeyboardArrowRightIcon
)<ArrowStyleProps>(({ arrowColor }) => ({
  color: arrowColor ? colorsMap[arrowColor] : colors.gray.gray20,
  cursor: 'pointer'
}))

const getBackgroundColor = (
  disabled: boolean,
  uiTheme: 'light' | 'dark' | 'gray'
) => {
  if (disabled) {
    return uiTheme === 'dark' ? colors.gray.gray75 : colors.gray.gray20
  } else {
    return colors.yellow
  }
}

const largeBtn = 150
const mediumBtn = 100
const smallBtn = 75

type SelectProps = {
  btnColor: 'green' | 'blue'
  btnStyle: string
  active?: boolean
  hidden?: boolean
  shadow?: boolean
  size?: 'small' | 'medium' | 'large'
}

export const ActionButtonCon = styled('button')<SelectProps>(
  ({ theme, hidden, btnStyle, active, shadow, btnColor }) => ({
    color:
      active ? 
      colors.black :
      colors.lightGrey,
    display: hidden === true ? 'none' : 'inline-block',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    borderRadius: theme.spacing(1),
    border: btnStyle === 'solid' ? '1px solid #000' : 'none',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    width: largeBtn,
    cursor: active ? 'pointer' : 'not-allowed',
    backgroundColor:
      btnStyle !== 'solid'
        ? 'transparent'
        : active && btnColor === 'green'
        ? colors.green.green
        : active && btnColor === 'blue'
        ? colors.lightBlue
        : colors.lightGrey,
    boxShadow:
      btnStyle === 'solid' && shadow
        ? '1px 2px 20px 5px #CECECE'
        : 'none',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor:
        btnStyle === 'solid' && active
          ? colors.blue
          : colors.lightestGrey,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      width: mediumBtn,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      padding: `${theme.spacing(0.5)}px ${theme.spacing(0.5)}px`,
      width: smallBtn,
    },
  })
)