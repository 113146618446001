import React, { useState, useEffect } from 'react'
import { SnackbarCon } from './styles'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
type SnackbarProps = {
  text: string
  color: 'red' | 'blue' | 'green'
  show: boolean
}

export interface State extends SnackbarOrigin {
  open: boolean
}

export const EmailSuccessSnackbar: React.FC<SnackbarProps> = ({
  text,
  color,
  show
}) => {
  const [state, setState] = useState<State>({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  })
  const { vertical, horizontal, open } = state

  useEffect(() => {
    setState({
        open: show,
        vertical: 'bottom',
        horizontal: 'center',
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  return (
    <Snackbar
      autoHideDuration={10000}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      key={vertical + horizontal}
    >
       <SnackbarCon color={color}>
        <p>{text}</p>
      </SnackbarCon>
    </Snackbar>
  )
}
