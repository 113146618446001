import { RadioButtonUncheckedOutlined } from "@mui/icons-material"
import { colors } from "../consts"
import { styled } from '@mui/system'

const RadioUncheckedOutlineCon = styled(RadioButtonUncheckedOutlined)<{iconColor?: string}>(
    ({iconColor}) => ({
    color: iconColor || colors.gold,
    height: 20,
  }))

type RadioUncheckedOutlineProps = {
    iconColor?: string
}

export const RadioUncheckedOutline: React.FC<RadioUncheckedOutlineProps> = ({iconColor}) => {
    return (
        <RadioUncheckedOutlineCon iconColor={iconColor}/>
    )
}