import React, { useContext, useEffect, useState } from 'react'
import { SignInModal } from '../../Components/Auth'
import {
  getFirAuth,
  getGoogleAuthCredential,
  PROVIDERS,
  getActionCodeSettings,
} from './firebaseConnect'
import { getCurrentUser, NO_USER, setUpdatedUserData } from './AuthData'
import type { GCredential } from './firebaseConnect'
import { getOrCreateUser } from '../Users/userProfile'
import { GOOGLE_CLIENT_ID } from '../../Configs/config'
import {
  checkIfEmbededInNativeApp,
  logEvent,
  removeLocalStorageItem,
  resetWindowLocationWithoutParams,
  useCookieStorage,
} from '../../Utils'
import {
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithCredential,
  getRedirectResult,
  signInWithRedirect,
  signInWithPopup,
  signInAnonymously,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  Auth,
  ConfirmationResult,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithCustomToken,
} from 'firebase/auth'
import { EmailAuthPrompt } from '../../Components/Auth/EmailAuthPrompt'
import { useQuery } from '../../Routing'
import { User } from '../types/users.types'
import { logBrazeEvent } from '../../Utils/analytics/brazeAnalytics'
import { BRAZE_EVENTS, gamificationEngagements } from '../../Constants'
import { LinkContext } from '../../Context/LinkContext'
import branch from 'branch-sdk'
import { logFirEvent } from '../../Utils/analytics/firAnalytics'
import { ANALYTICS_TAGS } from '../../Constants/analytics'

declare global {
  interface Window {
    google: any
  }
}

type AuthContextType = User & {
  isLoading: boolean
  showEmailConfirm: boolean
  brazeInitialized: boolean
  login: (extraFields?: string[]) => void
  logout: () => void
  sendPhoneVerification: (
    phone: string
  ) => Promise<ConfirmationResult | null>
  handleEmailLinkFromConfirmPrompt: (em: string) => Promise<boolean>
  onEmailPromptHandle: () => void
  updateProfileInState: (userItem: any) => void
  updateFavTeamInState: (favTeam: string) => void
  updateUserFieldInState: (fieldName: keyof User, value: any) => void
  refetchUser: () => Promise<void>
  getMissingUserFields: (fields: string[]) => string[]
}

export const AuthContext = React.createContext<AuthContextType | null>(
  null
)

type AuthProviderProps = {
  allowAnonymous?: boolean
  children?: React.ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({
  allowAnonymous = false,
  children,
}) => {
  let query = useQuery()
  const { getCookie, setCookie, removeCookie } = useCookieStorage()
  const {
    getMasterLinkParamsFromLocal,
    sendMasterBrazeEvents,
    branchInitialized,
  } = useContext(LinkContext)!
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showLogin, setShowLogin] = useState<{
    show: boolean
    extraFields?: string[]
  }>({ show: false, extraFields: [] })
  const [showEmailConfirm, setShowEmailConfirm] =
    useState<boolean>(false)
  const [brazeInitialized, setBrazeInitialized] =
    useState<boolean>(false)

  useEffect(() => {
    //Check if pending redirect
    getAuthRedirectResult()

    onAuthChange() //Turn on auth listener

    //Cleanup
    return () => onAuthChange()
  }, [])

  /**
   * When the auth state changes, either user is logged in or logged out
   */
  const onAuthChange = () => {
    const auth = getFirAuth()

    if (auth) {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        //Email link signup
        handleEmailLink()
      }
      //Check custom token query param
      let fbctQuery = query.get('fbct')
      if (fbctQuery) {
        handleCustomToken(fbctQuery)
      }

      initializeGSI() //init Google One Tap

      onAuthStateChanged(auth, (userRes) => {
        setIsLoading(true)
        if (userRes) {
          // User is logged in with firebase
          const usr = getCurrentUser()

          if (userRes.isAnonymous) {
            handleGoogleOneTapPrompt(usr) //Decide to show Google One Tap or not by firebase user
          }

          if (!usr.isAnonymous && usr.isLoggedIn) {
            //Create or update user in PLL database
            let firUsr = usr
            //Append master Link params
            let mParams = getMasterLinkParamsFromLocal()
            if (mParams?.osc) {
              firUsr = { ...firUsr, ...mParams }
            }
            getOrCreateUser(firUsr).then((dbUser: User) => {
              //User existed or was added, update in state
              setUserDataInState(dbUser)
              if (usr.uid) {
                //lazy load braze
                import('../../Configs/Braze/braze.config').then(
                  (braze) => {
                    let success = braze.initBraze(usr.uid!)
                    if (success) {
                      setBrazeInitialized(true)
                      branch.setIdentity(usr.uid!)
                      sendMasterBrazeEvents()
                    }
                  }
                )
              }
            })
          } else {
            setUser(usr as User)
            setShowLogin({ show: false, extraFields: [] })
            setIsLoading(false)
          }
        } else {
          // User is logged out

          //Decide to show Google One Tap or not
          handleGoogleOneTapPrompt(NO_USER)

          if (allowAnonymous) {
            signInAnonymously(auth)
          }
          setIsLoading(false)

          //Logout branch user if one exists
          branchInitialized && branch.logout()
        }
      })
    } else {
      setUser(NO_USER)
      setIsLoading(false)
      setShowLogin({ show: false, extraFields: [] })
      if (checkIfEmbededInNativeApp()) {
        logFirEvent(`firebase_not_initialized_onAuthChange_embeded`)
      } else {
        logFirEvent(`firebase_not_initialized_onAuthChange`)
      }
    }
  }

  /**
   * Loading the google one tap signup
   */
  const initializeGSI = () => {
    let isEmbedded = checkIfEmbededInNativeApp()
    if (
      !isEmbedded &&
      window &&
      window?.google &&
      window.google?.accounts &&
      GOOGLE_CLIENT_ID
    ) {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: (token: GCredential) => {
          handleToken(token)
        },
      })
    }
  }

  /**
   * Refetch user data from backend (pull to refresh)
   */
  const refetchUser = async () => {
    let usr = await getOrCreateUser(user)
    if (!user) return
    setUserDataInState(usr)
  }

  const setUserDataInState = async (usr: User) => {
    const userAuth = setUpdatedUserData(usr)
    setUser(userAuth)
    setIsLoading(false)
  }

  /**
   * Replace profile in state with new user data. Good for updating multiple fields
   */
  const updateProfileInState = (userItem: any) => {
    const currentAuth = { ...user }
    setUser({ ...currentAuth, ...userItem })
    return
  }

  const updateUserFieldInState = (
    fieldName: keyof User,
    value: any
  ) => {
    //@ts-ignore
    //TODO: change back when we have time
    setUser({ ...user, [fieldName]: value })
  }

  /**
   * Get fields user has not filled in on profile
   */
  const getMissingUserFields = (fields: string[]): string[] => {
    if (!user || fields.length === 0) return []
    return fields.filter(
      (field) => field in user && !user[field as keyof User]
    )
  }

  const handleGoogleOneTapPrompt = (usr: User) => {
    let isEmbedded = checkIfEmbededInNativeApp()
    if (
      !isEmbedded &&
      window?.google?.accounts &&
      window.google.accounts?.id &&
      GOOGLE_CLIENT_ID
    ) {
      if (!usr.isLoggedIn || usr.isAnonymous) {
        //Show one click
        window.google.accounts.id.prompt()
        return
      }
      if (usr.isLoggedIn && !usr.isAnonymous) {
        //Hide one click after log in
        window.google.accounts.id.cancel()
      }
    }
    return
  }

  const handleToken = async (token: any) => {
    const auth = getFirAuth()
    if (auth) {
      let cred = await getGoogleAuthCredential(token.credential)
      auth && (await signInWithCredential(auth, cred))
    }
  }

  /**
   * Handle if custom token is passed in url
   */
  const handleCustomToken = async (tk: string) => {
    const auth = getFirAuth()
    if (auth) {
      try {
        const result = await signInWithCustomToken(auth, tk)
        if (result?.user?.uid) {
          //Set false for shouldSendEvent because already logged in
          loginWithBraze(result.user.uid, false)
          resetWindowLocationWithoutParams(['fbct'])
        }
      } catch (err) {
        console.log(err)
        resetWindowLocationWithoutParams(['fbct'])
        Promise.resolve()
      }
    }
  }

  const handleEmailLink = async () => {
    const auth = getFirAuth()
    let email: string | null = null
    if (auth) {
      let email = ''
      if (window?.localStorage) {
        email = window.localStorage.getItem('pll_temp_em') || ''
      }
      if (!email) {
        email = tryEmFromCookie()
      }
      //Show email input
      if (!email) return setShowEmailConfirm(true)

      try {
        const result = await signInWithEmailLink(
          auth,
          email!,
          window.location.href
        )
        if (result?.user?.uid) {
          window.localStorage.removeItem('pll_temp_em')
          removeCookie('pll_temp_em')
          loginWithBraze(result.user.uid)
        }
      } catch (err) {
        logFirEvent('loginError_handleEmailLink', {
          provider: 'email',
          error: err,
          product: 'f2p',
        })
        console.log(err)
      }
    }
  }

  /**
   * Check temp email from cookie
   */
  const tryEmFromCookie = () => {
    let email = ''
    const cookieEm = getCookie('pll_temp_em')

    if (cookieEm) {
      email = cookieEm.toLowerCase()
    }
    return email
  }

  const handleEmailLinkFromConfirmPrompt = async (em: string) => {
    const auth = getFirAuth()
    let email = em.toLowerCase()
    if (auth) {
      try {
        const result = await signInWithEmailLink(
          auth,
          email,
          window.location.href
        )
        if (result?.user?.uid) {
          window.localStorage.removeItem('pll_temp_em')
          removeCookie('pll_temp_em')
          loginWithBraze(result.user.uid)
        }
        return true
      } catch (err) {
        logFirEvent('loginError_handleEmailLinkFromConfirmPrompt', {
          provider: 'email',
          error: err,
          product: 'f2p',
        })
        console.log(err)
        Promise.reject(new Error('fail')).then(() => {
          return false
        })
      }
    } else {
      Promise.reject(new Error('fail')).then(() => {
        return false
      })
    }
    return false
  }

  const onEmailPromptHandle = () => {
    setShowEmailConfirm(!showEmailConfirm)
  }

  const signInWithSocial = async (provider: string) => {
    const auth = getFirAuth()
    if (auth) {
      const p = PROVIDERS.google
      if (!p) return
      try {
        if (provider === 'google') {
          await signInWithGooglePopup(auth)
        } else {
          await signInWithRedirect(auth, p)
        }
      } catch (err: any) {
        console.log(err)
        logFirEvent('loginError_signInWithSocial', {
          provider: provider,
          error: err,
          product: 'f2p',
        })
        setShowLogin({ show: !setShowLogin, extraFields: [] })
        throw new Error(err)
      }
    }
  }

  /**
   * Use sign up with popup for just Gmail
   */
  const signInWithGooglePopup = async (auth: Auth) => {
    try {
      let result = await signInWithPopup(auth, PROVIDERS.google)
      if (result?.user?.uid) {
        loginWithBraze(result.user.uid)
      }
    } catch (err: any) {
      console.log(err)
      logFirEvent('loginError_signInWithGooglePopup', {
        provider: 'google',
        error: err,
        product: 'f2p',
      })
      GoogleAuthProvider.credentialFromError(err)
    }
  }

  const getAuthRedirectResult = async () => {
    const auth = getFirAuth()
    if (auth) {
      try {
        let result = await getRedirectResult(auth)
        if (result?.user?.uid) {
          loginWithBraze(result.user.uid)
        }
      } catch (err: any) {
        console.log(err)
        if (!err.credential) return
        await signInWithCredential(auth, err.credential)
      }
    }
  }

  const signInWithEmLink = async (
    redirectTo: string,
    email: string
  ) => {
    const auth = getFirAuth()

    if (auth) {
      let actionCodeSettings = getActionCodeSettings(
        redirectTo || window.location.hostname
      )
      try {
        await sendSignInLinkToEmail(auth, email, actionCodeSettings)
        window.localStorage.setItem('pll_temp_em', email)
        setCookie('pll_temp_em', email)
        return true
      } catch (err: any) {
        console.log(err)
        return false
      }
    }
    return false
  }

  // PHONE LOGIN //
  const sendPhoneVerification = async (phoneNumber: string) => {
    let auth = getFirAuth()
    let verifier = getAppVerifier('phone-signin-btn', auth)
    if (verifier && auth) {
      try {
        let confirmationResult: ConfirmationResult =
          await signInWithPhoneNumber(auth, phoneNumber, verifier)
        return confirmationResult
      } catch (err) {
        console.log(err)
        logFirEvent('loginError_sendPhoneVerification', {
          provider: 'phone',
          error: err,
          product: 'f2p',
        })
        return null
      }
    } else {
      return null
    }
  }

  const getAppVerifier = (
    verifierId: string | HTMLElement,
    auth: Auth | undefined
  ) => {
    if (auth) {
      return new RecaptchaVerifier(auth, verifierId, {
        size: 'invisible',
      })
    } else {
      return null
    }
  }

  const login = (extraFields?: string[]) => {
    setShowLogin({ show: true, extraFields: extraFields || [] })
  }

  const logout = () => {
    const fba = getFirAuth()
    if (fba) {
      if (localStorage) {
        removeLocalStorageItem('bracketCashTag')
        removeLocalStorageItem('bracketShareLink')
        removeLocalStorageItem('localBracketSelections')
        removeLocalStorageItem('masterLinkParams')
      }
      setUser(NO_USER)
      setShowLogin({ show: false, extraFields: [] })
      fba.signOut()
      setIsLoading(false)
    }
    branchInitialized && branch.logout()
  }

  const handleToggleLogin = () => {
    setShowLogin({ show: !setShowLogin, extraFields: [] })
  }

  const updateFavTeamInState = (favTeam: string) => {
    let usr = user ? { ...user } : { ...NO_USER }
    setUser({ ...usr, favTeam: favTeam })
  }

  const loginWithBraze = async (
    userId: string,
    shouldSendEvent: boolean = true
  ) => {
    //shouldSendEvent false for logging in through embeded browser
    if (!brazeInitialized) {
      await import('../../Configs/Braze/braze.config').then((braze) => {
        let success = braze.initBraze(userId)
        if (success) {
          setBrazeInitialized(true)
          shouldSendEvent &&
            logBrazeEvent(gamificationEngagements.LOG_IN)
        }
      })
    } else {
      shouldSendEvent && logBrazeEvent(BRAZE_EVENTS.log_in)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        ...(user || { ...NO_USER }),
        isLoading,
        showEmailConfirm,
        brazeInitialized,
        handleEmailLinkFromConfirmPrompt,
        login,
        logout,
        sendPhoneVerification,
        onEmailPromptHandle,
        updateFavTeamInState,
        updateProfileInState,
        updateUserFieldInState,
        refetchUser,
        getMissingUserFields,
      }}
    >
      {showLogin.show && (
        <SignInModal
          onClose={() => {
            logEvent(ANALYTICS_TAGS.login_cancelled)
            handleToggleLogin()
          }}
          signInWithSocial={signInWithSocial}
          signInWithEmLink={signInWithEmLink}
          extraFields={showLogin.extraFields}
        />
      )}
      {showEmailConfirm && <EmailAuthPrompt />}
      {children}
    </AuthContext.Provider>
  )
}
