import { formatImg } from '../Users/userUtils'
import { FirebaseUser, User } from '../types/users.types'
import { getFirAuth } from './firebaseConnect'

export type FavType = 'topics' | 'favPlayers' | 'favTeam'

export const NO_USER: User = {
  isLoggedIn: false,
  isAnonymous: false,
  uid: null,
  email: null,
  favTeam: null
}

/**
 * Get currently logged user data from firebase
 */
export function getCurrentUser(): FirebaseUser {
  const usr = getFirAuth()?.currentUser
  if (usr && usr.uid) {
    // logged in user
    let firUser: FirebaseUser = {
      isLoggedIn: usr.isAnonymous ? false : true,
      isAnonymous: usr.isAnonymous,
      uid: usr.uid,
      favTeam: null
    }
    if (usr?.displayName) {
      firUser['firstName'] = usr.displayName.split(' ')[0]
      firUser['lastName'] = usr.displayName.split(' ')[1]
    }
    if (usr?.email) {
      firUser['email'] = usr.email
    }
    if (usr?.phoneNumber) {
      firUser['phone'] = usr.phoneNumber
    }
    if (usr?.photoURL) {
      firUser['profileUrl'] = usr.photoURL
    }

    return firUser
  }
  // not logged in
  return NO_USER
}

/**
 * Get currently logged user data
 */
export function setUpdatedUserData(userRes: any): User {
  const usr = getFirAuth()?.currentUser
  if (usr && usr.uid) {
    // logged in user
    let userObj = {
      isLoggedIn: !usr.isAnonymous ? true : false,
      isAnonymous: usr.isAnonymous,
      uid: usr.uid,
      firstName: userRes?.firstName,
      lastName: userRes?.lastName,
      email: userRes?.email,
      profileUrl: userRes.profileUrl ? formatImg(userRes.profileUrl) : '',
      phone: usr?.phoneNumber ? usr.phoneNumber : userRes?.phone ?  userRes.phone : '',
      birthday: userRes?.birthday || '',
      gamification: userRes?.gamification ? userRes.gamification : false,
      boostedGames: userRes?.boostedGames ? userRes.boostedGames : [],
      cashTag: userRes?.cashTag ? userRes.cashTag : '',
      favTeam: userRes?.favTeam ? userRes.favTeam : '',
      favPlayers: userRes?.favPlayers ? userRes.favPlayers : [],
      zip: userRes?.zip ? userRes.zip : '',
    }
    if (userRes?.username) {
      userObj['username'] = userRes.username
    }
    if (userRes?.pllNation) {
      userObj['pllNation'] = userRes.pllNation
    }
    return userObj
  }
  // not logged in
  return NO_USER
}
