import React, { useState, useEffect } from 'react'
import { SnackbarCon } from './styles'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar'
import { PickemContext } from '../../Context'
type SnackbarProps = {
  text: string
  color: 'red' | 'blue' | 'green'
}

export interface State extends SnackbarOrigin {
  open: boolean
}

export const PickemSnackbar: React.FC<SnackbarProps> = ({
  text,
  color
}) => {
    const { showSnackbar } = React.useContext(PickemContext)!
  const [state, setState] = useState<State>({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  })
  const { vertical, horizontal, open } = state

  useEffect(() => {
    setState({
        open: showSnackbar,
        vertical: 'bottom',
        horizontal: 'center',
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSnackbar])

  const handleClose = () => {
    setState({ ...state, open: false })
  }

  return (
    <Snackbar
      autoHideDuration={2000}
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      onClose={handleClose}
      key={vertical + horizontal}
    >
      <SnackbarCon color={color}>
        <p>{text}</p>
      </SnackbarCon>
    </Snackbar>
  )
}
