import { styled } from '@mui/material/styles'
import { keyframes } from '@mui/system'
import { colors } from '../consts'

type ImageProps = {
  loaded: boolean
}
const loading = keyframes({
  '0%': {
    left: '-100%',
  },
  '100%': {
    left: '100%',
  },
})

export const ImageWrapper = styled('div')<ImageProps>(({ loaded }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'auto',

  '& ::before': {
    content: loaded ? 'none' : '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `linear-gradient(90deg, ${colors.offWhite} 15%, ${colors.gray.gray20} 50%, ${colors.offWhite} 85%)`,
    borderRadius: 10,
    animation: loaded ? 'none' : `${loading} 0.5s infinite`,
  },
  '& ::after': {
    background: 'none'
  }
}))
