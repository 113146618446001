import { styled } from '@mui/system'
import { ArrowForward } from '@mui/icons-material'
import { colors, fontSizes } from '../consts'

export const RightArrowCon = styled(ArrowForward)(({ theme }) => ({
  backgroundColor: colors.gray.gray60,
  padding: theme.spacing(0.1),
  borderRadius: 100,
  color: colors.gray.gray90,
  fontSize: fontSizes.medium,
}))

export const RightArrow = () => {
  return <RightArrowCon />
}
