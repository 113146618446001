import { useContext, useEffect, useState, useMemo } from 'react'
import { SingleFantasyGroupCompetition } from '../../../Api'
import { FantasyGroupContext } from '../../../Context'
import { IneligibleGroupIndicator } from './IneligibleGroupIndicator'
import { PrivateGroupCompIndicator } from './PrivateGroupCompIndicator'
import { currentTime } from '../../../Utils/DateTime/dateTimeHelpers'

type CompetitionIndicatorProps = {
  competition?: SingleFantasyGroupCompetition
}

export const SingleGroupCompetitionIndicator: React.FC<
  CompetitionIndicatorProps
> = ({ competition }) => {
  const { singleGroup } = useContext(FantasyGroupContext)!

  const hasAllowedUsers = useMemo(() => {
    if (!singleGroup || !competition || !competition.minUsers)
      return false
    const hasMinUsers =
      singleGroup.totalUsers >= (competition.minUsers ?? 3)
    const underMaxUsers =
      singleGroup.totalUsers <= (competition.maxUsers ?? 15)
    return hasMinUsers && underMaxUsers
  }, [singleGroup, competition])

  const [state, setState] = useState({
    joinedComp: false,
    hasMinUsers: false,
  })

  useEffect(() => {
    const hasJoined = singleGroup?.competitions?.some(
      (comp) => comp.competitionId === competition?.competitionId
    )
    setState({
      joinedComp: hasJoined ?? false,
      hasMinUsers: hasAllowedUsers,
    })
  }, [singleGroup, competition, hasAllowedUsers])

  const { joinedComp, hasMinUsers } = state

  if (
    !competition ||
    !singleGroup?.private ||
    (currentTime > competition.entryCloseTime && !joinedComp)
  ) {
    return null
  }

  if (!hasMinUsers && joinedComp) {
    return <IneligibleGroupIndicator competition={competition} />
  }

  return <PrivateGroupCompIndicator competition={competition} />
}

