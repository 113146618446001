import { MoreVert } from '@mui/icons-material'
import { styled } from '@mui/material'
import { colors } from '../consts'

type EllipsesVerticalProps = {
  iconColor?: string
  height?: number
  width?: number
}

const EllipsesVerticalCon = styled(MoreVert)<EllipsesVerticalProps>(
  ({ theme, iconColor, height }) => ({
    cursor: 'pointer',
    color: iconColor || colors.black,
    height: height || 50,
  })
)

export const EllipsesVertical: React.FC<EllipsesVerticalProps> = ({
  iconColor,
  height,
}) => {
  return <EllipsesVerticalCon iconColor={iconColor} height={height} />
}
