import { Close } from '@mui/icons-material'
import { styled } from '@mui/system'
import { colors, fontSizes } from '../consts'

const CloseIconCon = styled(Close)<{iconColor?: string, size?: number}>(
    ({theme, iconColor, size}) => ({
        color: iconColor || colors.white,
        fontSize: size || fontSizes.medium,
        fontWeight: '700',
        cursor: 'pointer'
    })
)

export const CloseIcon: React.FC<{iconColor?: string, size?: number}> = ({iconColor, size}) => {
    return (
        <CloseIconCon iconColor={iconColor} size={size} />
    )
}