import { getFirAnalytics } from '../../Api/firebase/firebaseConnect'
import { logEvent as firLogEvent, setUserProperties } from "firebase/analytics"
import { logBrazeEvent } from './brazeAnalytics'

type UserParams = {
    params: any
}

/**
 *
 * Sends firebase and braze event in one function
 */
export const logEvent = (name: string, params?: any | undefined ) => {
    const fa = getFirAnalytics()
    firLogEvent(fa, name, params)
    logBrazeEvent(name, params)
}

/**
 *
 * Sends only firebase event
 */
export const logFirEvent = (name: string, params?: any | undefined ) => {
    const fa = getFirAnalytics()
    firLogEvent(fa, name, params)
}

export const setUserProperty = (property: UserParams) => {
    const fa = getFirAnalytics()
    setUserProperties(fa, property)
}