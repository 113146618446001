import {
  API_ROOT_URL,
  GRAPHQL_ROOT_URL,
  GRAPHQL_ACCESS_KEY,
} from '../../Configs/config'

export type Methods = 'POST' | 'GET' | 'PUT'
export type QueryParams = { [key: string]: string | number | undefined }

export type APIRequestParams = {
  method?: Methods
  apiVersion?: string
  suffix?: string
  headerParams?: QueryParams
  queryParams?: QueryParams
  body?: BodyInit
}

export type PreparedParams = {
  url: string
  opts: RequestInit
}

export type APIRes = {
  json: any
  status: number | null
}

export function applyParams(url: URL, params?: QueryParams) {
  if (params !== undefined) {
    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined) {
        if (typeof value === 'number') {
          url.searchParams.append(key, value.toString())
        } else {
          url.searchParams.append(key, value)
        }
      }
    }
  }
}

function prepareApiRequest(
  endpoint: string,
  {
    method,
    apiVersion,
    suffix,
    headerParams,
    queryParams,
    body,
  }: APIRequestParams,
  overwriteURL?: string
): PreparedParams {
  let url: URL
  if (overwriteURL) {
    url = new URL(overwriteURL)
  } else {
    url = new URL(
      `${API_ROOT_URL}/v${apiVersion}/${endpoint}${
        suffix ? `${suffix}` : ''
      }`
    )
  }
  const headers = {
    'content-type': 'application/json',
    authSource: 'f2p',
    ...headerParams,
  }
  applyParams(url, queryParams)
  const opts: RequestInit = { method: method, headers: headers }
  if (body) opts.body = body //For POST requests
  return {
    url: url.toString(),
    opts: opts,
  }
}

export async function callEndpoint(
  url: string,
  opts: RequestInit
): Promise<APIRes> {
  if (opts.method === undefined) {
    console.log('callEndpoint undefined method')
    return { json: null, status: null }
  }

  // fetch options
  try {
    const response = await fetch(url, opts)
    if (!response.ok)
      throw new Error(
        `callEndpoint invalid response status: ${response?.status}`
      )

    let json: any
    json = await response.json()

    // if res ult has no valid body
    if (json === null) {
      throw new Error('callEndpoint Missing response body')
    }
    return { json: json, status: response?.status }
  } catch (e) {
    console.log(e)
    return { json: null, status: null }
  }
}

export async function makeAPIRequest(
  endpoint: string,
  {
    method = 'GET',
    apiVersion = '2',
    suffix,
    queryParams,
    headerParams,
    body,
  }: APIRequestParams,
  overwriteURL?: string
): Promise<APIRes | undefined> {
  const { url, opts } = prepareApiRequest(
    endpoint,
    {
      method,
      apiVersion,
      suffix,
      queryParams,
      headerParams,
      body,
    },
    overwriteURL
  )
  try {
    const res = await callEndpoint(url, opts)
    return res
  } catch (err: unknown) {
    console.error(err)
    return
  }
}

export async function callGraphQL<T>(
  query: string,
  variables: any = {},
  gamification: number = 0,
  token?: string
): Promise<T | undefined> {
  const body = {
    operationName: null,
    variables: variables,
    query: query,
  }
  let headerObj = {
    'content-type': 'application/json',
    Authorization: `Bearer ${token || GRAPHQL_ACCESS_KEY}`,
    gamification: gamification.toString(),
  }
  const opts: RequestInit = {
    method: 'POST',
    headers: headerObj,
    body: JSON.stringify(body),
  }
  try {
    const response = await fetch(GRAPHQL_ROOT_URL, opts)
    if (!response.ok) {
      throw new Error(
        `GraphQL fetch error with status: ${response.status}`
      )
    }
    const data = await response.json()
    if (!data || !data.data) {
      throw new Error('No data returned by GraphQL.')
    }
    return data.data as T
  } catch (err) {
    console.error('GraphQL error: ', err)
    return {} as T
  }
}
