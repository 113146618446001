export const joinFantasyGroupMutation = `
mutation($userId: String!, $groupId: Int!) {
    joinFantasyGroup(userId: $userId, groupId: $groupId) {
      responseCode
      body
      error
    }
  }
`

export const createFantasyGroupMutation = `
mutation($input: CreateFantasyGroupInput!) {
    createFantasyGroup(input: $input) {
        id
        name
        description
        groupImg
        private
        userRanks{
            weekRank
            overallRank
        }
    }
  }
`

export const submitFantasyTeamMutation = `mutation($userId: String!, $week: Int!, $playerInfoIds: [Int]!) {
  submitFantasyTeam(userId: $userId, week: $week, playerInfoIds: $playerInfoIds) {
    id
  }
}
`

export const deleteUserFromFantasyGroupMutation = `
  mutation($input: DeleteFantasyGroupMember!) {
    deleteUserFromFantasyGroup(input: $input){
      id
    }
  }
`

export const deleteFantasyGroupMutation = `
  mutation($input: DeleteFantasyGroupInput!){
    deleteFantasyGroup(input: $input) {
      id
    }
  }
`

export const leaveFantasyGroupMutation = `
  mutation($input: LeaveFantasyGroupInput!){
    leaveFantasyGroup(input: $input) {
      id
    }
  }
`

export const updateFantasyGroupMutation = `
  mutation($input: UpdateFantasyGroupInput!){
    updateFantasyGroup(input: $input) {
      id
    }
  }
`

export const joinFantasyGroupToCompetition = `
  mutation($input: JoinFantasyGroupCompetitionsInput!){
    joinFantasyGroupCompetitions(input: $input){
      id
    }
  }
`