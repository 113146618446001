export const FANTASY_USER_QUERY = `
query ($userId: String!, $year: Int!, $week: Int!) {
    fantasyUserPicks(userId: $userId, year: $year, week: $week) {
        id
        firebaseId
        name
        teamName
        profileUrl
        fanScore
        seasonTotalPoints
        totalRecord
        weekPts
        challengeCode
        projectedPoints
        postSeasonTotalPoints
        rank
        weekPick {
            week
            weeklyTotalPoints
            weeklyRank
            wins
            losses
            ties
            players {
                firstName
                lastName
                slug
                officialId
                injuryStatus
                injuryDescription
                profileUrl
                currentGame(year: $year, week: $week) {
                    startTime
                    eventStatus
                    seasonSegment
                    week
                    gameStatsSummary
                    homeTeam {
                        locationCode
                    }
                    awayTeam {
                        locationCode
                    }
                }
                currentTeam {
                  officialId
                  jerseyNum
                  locationCode
                  conference
                  urlLogo
                }
                fantasyInfo(week: $week) {
                  seasonTotalPts
                  avgPPG
                  salary
                  projectedPoints
                  realtimeProjectedPoints
                  position
                  weekPts
                  eventId
                  gameNumber
                  fantasyPlayerInfoId
                }
            }
        }
        challengers {
            id
            firebaseId
            teamName
            weekPts
            profileUrl
            fanScore
            recordAgainst
            projectedPoints
            pageInfo {
              hasNextPage
              endCursor
              startCursor
            }
          }
        groups {
            id
            name
            description
            groupImg
            private
            totalUsers
            userRanks{
                weekRank
                overallRank
                postSeasonRank
            }
        }
    }
}
`

export const fantasyUserGroupsQuery = `
query ($userId: String!, $year: Int!, $week: Int!) {
    fantasyUserPicks(userId: $userId, year: $year, week: $week) {
        groups {
            id
            name
            description
            groupImg
            private
            totalUsers
            userRanks{
                weekRank
                overallRank
                postSeasonRank
            }
            competitions {
                competitionId
              }
        }
    }
}
`

export const fantasyGroupsQuery = `
    query ($year: Int!) {
        allFantasyGroups(year: $year) {
            id
            name
            year
            description
            groupImg
            totalUsers
            userRank
            competitions {
                competitionId
              }
        }
    }
`
export const fantasySingleGroupQuery = `
query ($week: Int, $groupId: Int!, $sortBy: LeaderboardSort) {
    fantasyGroup(week: $week, groupId: $groupId, sortBy: $sortBy) {
        id
        name
        year
        description
        groupImg
        groupOwner
        totalUsers
        private
        seasonAvgPoints
        userRank
        userRanks{
            weekRank
            overallRank
            postSeasonRank
        }
        highlightedMembers {
            firebaseId
            name
            teamName
            profileUrl
            fanScore
            seasonTotalPoints
            weekPts
            rank
            seasonRank
            postSeasonRank
            postSeasonTotalPoints
        }
        leaderboard{
            firebaseId
            name
            teamName
            profileUrl
            seasonTotalPoints
            weekPts
            rank
            seasonRank
            postSeasonRank
            postSeasonTotalPoints
        }
        competitions {
            competitionId
            description
            img
            name
            startTime
            entryCloseTime
            endTime
            groupRank
            minUsers
            maxUsers
            sponsor
            shareLink
            totalGroupCount
            weekAvgPoints
            rules
            leaderboard {
                id
            }
            prize {
                title
                description
                image
            }
        }
    }
}
`

export const fantasyGroupCompetitionByIdQuery = `
    query ($competitionId: Int!, $limit: Int, $page: Int) {
        fantasyGroupCompetition(competitionId: $competitionId) {
            competitionId
            name
            description
            img
            totalGroupCount
            sponsor
            rules
            entryCloseTime
            prize {
                title
                description
                image
            }
            leaderboard(limit: $limit, page: $page) {
                id
                name
                description
                groupImg
                groupRank
                seasonAvgPoints
                weekAvgPoints
                competitionAvgPoints 
            }
        }
    }
`

export const allFantasyGroupCompetitions = `
    query ($year: Int!, $limit: Int) {
        allFantasyGroupCompetitions(year: $year, limit: $limit) {
            competitionId
            name
            description
            img
            weekAvgPoints
            seasonAvgPoints
            competitionAvgPoints
            groupRank
            sponsor
            totalGroupCount
            startTime
            entryCloseTime
            endTime
            minUsers
            maxUsers
            rules
            shareLink
            public
        }
    }
`

export const fantasyChallengerQuery = `
    query ($userId: String!, $year: Int!, $week: Int!) {
        fantasyUserPicks(userId: $userId, year: $year, week: $week) {
            id
            firebaseId
            teamName
            name
            weekPts
            profileUrl
            fanScore
            projectedPoints
            seasonTotalPoints
            recordAgainst
            weekPick {
                week
                weeklyTotalPoints
                weeklyRank
                wins
                losses
                ties
                players {
                    firstName
                    lastName
                    slug
                    officialId
                    injuryStatus
                    injuryDescription
                    profileUrl
                    currentGame(year: $year, week: $week) {
                        startTime
                        eventStatus
                        seasonSegment
                        week
                        gameStatsSummary
                        homeTeam {
                            locationCode
                        }
                        awayTeam {
                            locationCode
                        }
                    }
                    currentTeam {
                      officialId
                      jerseyNum
                      locationCode
                        conference
                      urlLogo
                    }
                    fantasyInfo(week: $week) {
                      seasonTotalPts
                      avgPPG
                      salary
                      projectedPoints
                      realtimeProjectedPoints
                      position
                      weekPts
                      eventId
                      gameNumber
                      fantasyPlayerInfoId
                      seasonWeeklyPts {
                        week
                        totalPts
                      }
                    }
                }
            }
        }
    }
`

export const generateTeamAIQuery = `
query($year: Int!, $week: Int!){
    generateFantasyTeamAI(year: $year, week: $week) {
      officialId
      firstName
    lastName
      profileUrl
      fantasyInfo(week: $week) {
        fantasyPlayerInfoId
        eventId
        salary
        projectedPoints
        position
        gameNumber
      }
      currentTeam {
        locationCode
      }
      currentGame {
        homeTeam {
            locationCode
        }
        awayTeam {
            locationCode
        }
      }
    }
  }
  `
