import { InfoOutlined } from '@mui/icons-material';
import { styled } from '@mui/material'
import { colors } from '../consts'

type InfoProps = {
  iconColor?: string
  height?: number
  width?: number
  fontSize?: number
}

const InfoCon = styled(InfoOutlined)<InfoProps>(
  ({ theme, iconColor, height }) => ({
    color: iconColor || colors.black,
    height: height || 50,
  })
)

export const InfoIcon: React.FC<InfoProps> = ({
  iconColor,
  height,
  fontSize = 'inherit',
}) => {
  return (
    <InfoCon
      height={height}
      sx={{ fontSize: fontSize, color: iconColor || colors.black }}
    />
  )
}