import { Schedule } from "@mui/icons-material"
import { colors } from "../consts"
import { styled } from '@mui/system'

const ClockCon = styled(Schedule)<{iconColor?: string}>(
    ({iconColor}) => ({
    color: iconColor || colors.white,
    height: 20,
  }))

type ClockProps = {
    iconColor?: string
}

export const Clock: React.FC<ClockProps> = ({iconColor}) => {
    return (
        <ClockCon iconColor={iconColor}/>
    )
}