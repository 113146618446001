import React, { useState, useEffect, useContext, useRef, Suspense } from 'react'
import { logEvent } from '../../Utils'
import { ConfirmationResult } from 'firebase/auth'
import { Desc, Head2 } from '../Typography/Text.styles'
import { Btn } from '../Buttons/Btn'
import { ErrorStyle, InputStyle, StyledMuiTelInput } from './styles'
import { colors } from '../../Components/consts'
import { AuthContext } from '../../Api/firebase'
import { ANALYTICS_TAGS } from '../../Constants/analytics'
import { logFirEvent } from '../../Utils/analytics/firAnalytics'

type PhoneSignInParams = {
  loginInput: string
  handleInputChange: (newVal: string) => string | undefined
  uiTheme: 'light' | 'dark'
}
export const PhoneSignIn: React.FC<PhoneSignInParams> = ({
  uiTheme = 'dark',
  loginInput,
  handleInputChange,
}) => {
  const { sendPhoneVerification } = useContext(AuthContext)!
  const [phone, setPhone] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [codeSent, setCodeSent] = useState<boolean>(false)
  const [phoneResult, setPhoneResult] =
    useState<ConfirmationResult | null>(null)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [showCodeInput, setShowCodeInput] = useState<boolean>(false)
  const [btnEnabled, setBtnEnabled] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const [matchIsValidTel, setMatchIsValidTel] = useState<Function | null>(null)

  useEffect(() => {
    if (loginInput.length > 0) setPhone(`+1` + loginInput)
      import('mui-tel-input')
      .then((module) => {
        setMatchIsValidTel(() => module.matchIsValidTel)
      })
      .catch((err) => console.error('Failed to load mui-tel-input:', err))
  }, [])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [phone])

  useEffect(() => {
    if (phoneResult) {
      setShowCodeInput(true)
    }
  }, [phoneResult])

  const handlePhoneChange = (newValue: string) => {
    if (matchIsValidTel) {
      let valid = matchIsValidTel(newValue)
      setErrorMsg('')
      handleInputChange(newValue)
      setPhone(newValue)
      valid && setBtnEnabled(true)
    }
  }

  const handlePhoneSignin = async () => {
    if (!btnEnabled) return
    setCodeSent(true)
    setBtnEnabled(false)
    let result = await sendPhoneVerification(phone)
    if (result) {
      setPhoneResult(result)
      setBtnEnabled(true)
    } else {
      setErrorMsg('Trouble texting the code')
      setBtnEnabled(true)
    }
  }

  const handleCodeChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let code = e.target.value
    setCode(code)
    if (code.length === 6) {
      setBtnEnabled(true)
    }
  }

  // Validate the code that user input
  const validateSMSCode = async () => {
    if (!btnEnabled) return
    setBtnEnabled(false)
    if (!phoneResult) {
      setErrorMsg('Error getting code')
      setBtnEnabled(true)
      return
    }
    try {
      let result = await phoneResult.confirm(code)
      // success
      logEvent(ANALYTICS_TAGS.phone_login_click, {
        path: window.location.href,
      })
    } catch (err) {
      logFirEvent('loginError_validateSMSCode', {
        provider: 'email',
        error: err,
        product: 'f2p',
      })
      alert('Something went wrong. Try again.')
      setBtnEnabled(true)
    }
  }

  const PhoneInputStyle = (props) => (
    <Suspense fallback={<div />}>
      <StyledMuiTelInput {...props} />
    </Suspense>
  )

  return (
    <>
      <Head2
        justify="center"
        color={uiTheme === 'dark' ? colors.white : colors.text}
      >
        Sign In With Email or Phone
      </Head2>
      {!showCodeInput && (
        <Desc
          justify="center"
          color={uiTheme === 'dark' ? colors.white : colors.text}
        >
          Send a text with a unique code to your phone.
        </Desc>
      )}
      {showCodeInput && (
        <Desc justify="center" color={colors.blue}>
          A text was sent to your phone. Code expires in 5 minutes.
        </Desc>
      )}
      {!showCodeInput && (
        <PhoneInputStyle
          uiTheme={uiTheme}
          placeholder="Enter Phone Number"
          size="small"
          variant="outlined"
          value={phone}
          onChange={handlePhoneChange}
          defaultCountry={'US'}
          inputRef={inputRef}
        />
      )}
      {showCodeInput && (
        <InputStyle
          required
          uiTheme={uiTheme}
          type="number"
          variant="outlined"
          margin="normal"
          id="temailOutlined"
          helperText="Enter 6 digit code sent to phone"
          InputLabelProps={{
            shrink: true,
          }}
          value={code}
          onChange={(e) => {
            handleCodeChange(e)
          }}
        />
      )}
      {!showCodeInput && (
        <>
          <div id="phone-signin-btn" />
          <Btn
            btnStyle="primary"
            disabled={!btnEnabled}
            handleClick={handlePhoneSignin}
          >
            Send Code
          </Btn>
        </>
      )}
      {showCodeInput && (
        <>
          <Btn
            disabled={!btnEnabled}
            btnStyle="primary"
            handleClick={validateSMSCode}
          >
            Sign In
          </Btn>
        </>
      )}
      <ErrorStyle>{errorMsg}</ErrorStyle>
    </>
  )
}
