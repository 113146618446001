import React from 'react'
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/system'
import { colors } from '../../Components/consts'

type CheckMarkProps = {
    show: boolean
}

const CheckMarkCon = styled(CheckIcon)<CheckMarkProps>(
    ({ show }) => ({
        color: colors.black,
        opacity: show ? 1 : 0
    })
)

export const CheckMarkIcon: React.FC<CheckMarkProps> = ({ show }) => {
    return (
        <CheckMarkCon fontSize='small' show={show} />
    )
}