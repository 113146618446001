import { styled } from '@mui/material'
import { colors, fontSizes } from '../../../Components/consts'

type NavListItemProps = {
  pageId: number
  selected: number
  listLength: number
}

type NavListConProps = {
  height?: number
}

export const NavListCon = styled('ul')<NavListConProps>(
  ({ theme, height}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: height ? height : 50,
  margin: 0,
  padding: 0,
  backgroundColor: colors.black,
  listStyle: 'none',
}))

export const NavListItemCon = styled('li')<NavListItemProps>(
  ({ selected, pageId, listLength, theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: `${100/listLength}%`,
    borderBottom: pageId === selected ? `2px solid ${colors.gold}` : '',
    color: pageId === selected ? colors.gold : colors.gray.gray40,
    WebkitTapHighlightColor: 'transparent',
    '& .lnk': {
      display: 'flex',
      justifyContent: 'center',
      cursor: 'pointer',
      '& p': {
        [theme.breakpoints.down('md')]: {
          fontSize: fontSizes.small
        }
      }
    },
  })
)
