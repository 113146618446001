import { getFirAuth } from "../firebase"
import { QueryParams, makeAPIRequest } from "../utils/request"
import { userEndpoints } from "../utils/restEndpoints"
import { CompeteSummary } from "./compete.types"

/**
 * Fetch User Records
 */
export const fetchUserRecords =
  async (
    userId: string,
    year: number,
    seasonWeek: number,
    pllnWeek: number,
    recordType: string
  ): Promise<CompeteSummary[]> => {
    const usr = getFirAuth()?.currentUser
    if (!usr) return []
    let headerParams: QueryParams = { userId: userId }
    const token = await usr.getIdToken()
    headerParams.Authorization = `Bearer ${token}`
    try {
      const recordRes = await makeAPIRequest(userEndpoints.USER_RECORDS,
        {
          method: 'GET',
          apiVersion: '3',
          headerParams: headerParams,
          queryParams: {
            year: year,
            seasonWeek: seasonWeek,
            pllnWeek: pllnWeek,
            recordTypes: recordType
          },
        }
      )
      if (!recordRes?.json) return []
      return recordRes.json
    } catch (err: unknown) {
      console.error(err)
      return []
    }
  }