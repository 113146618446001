import React from 'react'
import { styled } from '@mui/material'
import { logEvent } from '../../Utils'
import { Img } from '../Images/Img'
import { ANALYTICS_TAGS } from '../../Constants/analytics'

export const PageHeadRoot = styled('div')(({ theme }) => ({
  position: 'relative',
  '& a': {
    '& img': {
      width: '100%',
    },
  },
  '& img': {
    width: '100%',
  },
}))

type PageHeaderProps = {
  img: string
  link?: string | null
  alt?: string
  title?: string
  description?: string
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  img,
  link,
  alt,
}) => {
  const log = (event: string) => {
    logEvent(event, {
      img: img,
      link: link,
    })
  }
  return (
    <PageHeadRoot>
      {link ? (
        <a
          href={link}
          onClick={() => log(ANALYTICS_TAGS.home_banner_click)}
        >
          <Img src={img} alt={alt} />
        </a>
      ) : (
        <Img src={img} alt={alt} />
      )}
    </PageHeadRoot>
  )
}
