import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react'
import { AuthContext } from '../../Api/firebase'
import {
  BadgeType,
  GamificationConfig,
  GamificationLevels,
  fetchAchievements,
  fetchGamificationConfig,
  fetchLeaderboardData,
} from '../../Api'
import {
  AchievementsGroup,
  GamificationFeedItem,
} from '../../Api/types/achievements.types'
import { claimDailyXP, fetchUserRewards } from '../../Api/PLLNation/pllNation.api'
import { SnackbarData } from '../../Api/types/picks.types'
import { GeneralSnackbar } from '../../Components/Snackbar/GeneralSnackbar'
import { PLLNSeasons, getHasClaimed, logEvent } from '../../Utils'
import { LeaderboardRes } from '../../Api/PLLNation/pllNation.types'
import { getFeatureFromConfig, getIsAboveLevel } from '../../Configs'
import { PLLNationUser } from '../../Api/types/users.types'
import { ANALYTICS_TAGS } from '../../Constants/analytics'

type GamificationContextType = {
  gamificationConfig: GamificationConfig | null
  achievementsGroup: AchievementsGroup | null
  loading: boolean
  isAchievementsLoading: boolean
  hasClaimedDailyXP: boolean
  claimLoading: boolean
  userPllNation: PLLNationUser
  leaderboardData: LeaderboardRes
  isLeaderboardLoading: boolean
  userFeed: GamificationFeedItem[]
  userExp: number
  pllnWeek: number
  pllnSeason: number
  userBenefits: GamificationLevels[] | null
  userBadges: BadgeType[] | null
  getUserRewards: (userId: string) => void
  handleClaimDailyXP: () => Promise<{
    success?: boolean
    status?: number | null
    error?: any
    err?: any
  } | null>
  profileRowClick: (title: string) => void | null
  handleUpdateUserExp: (exp: number) => void
  updateGamificationUserFieldInState: (fieldName: keyof PLLNationUser, value: any) => void
}

export const GamificationContext =
  createContext<GamificationContextType | null>(null)

export const GamificationProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const { uid, pllNation, firstName, lastName, favTeam, isLoggedIn } =
    useContext(AuthContext)!
  const [loading, setLoading] = useState<boolean>(true)
  const [isAchievementsLoading, setIsAchievementsLoading] =
    useState<boolean>(true)
  const [gamificationConfig, setGamificationConfig] =
    useState<GamificationConfig | null>(null)
  const [achievementsGroup, setAchievementsGroup] =
    useState<AchievementsGroup | null>(null)
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [snackbar, setSnackbar] = useState<SnackbarData>({
    text: '',
    color: 'green',
  })
  const [hasClaimedDailyXP, setHasClaimedDailyXP] =
    useState<boolean>(false)
  const [claimLoading, setClaimLoading] = useState<boolean>(false)
  const [leaderboardData, setLeaderboardData] =
    useState<LeaderboardRes>({
      seasonLeaderboard: [],
      weeklyLeaderboard: [],
    })
  const [isLeaderboardLoading, setIsLeaderboardLoading] =
    useState<boolean>(true)
  const [userPllNation, setUserPllNation] = useState<PLLNationUser>(
    {} as PLLNationUser
  )
  const [userFeed, setUserFeed] = useState<GamificationFeedItem[]>([])
  const [userExp, setUserExp] = useState<number>(pllNation?.exp || 0)
  const [pllnSeason, setPLLNSeason] = useState<number>(2023)
  const [pllnWeek, setPLLNWeek] = useState<number>(1)
  const [userBenefits, setUserBenefits] = useState<GamificationLevels[] | null>(null)
  const [userBadges, setUserBadges] = useState<BadgeType[] | null>(null)

  useEffect(() => {
    getSeasonAndWeek()
    if (!uid) {
      //Either not logged in or just logged out
      setLoading(false)
      setIsAchievementsLoading(false)
      return
    }
    getUserRewards(uid)
    setupGamificationData(uid)
  }, [uid])

  useEffect(() => {
    if (pllNation) {
      setUserPllNation({
        ...pllNation,
        userId: uid || '',
        firstName: firstName || '',
        lastName: lastName || '',
        favTeam: favTeam || '',
        exp: pllNation.weekExp || 0,
        seasonExp: pllNation.exp,
      })
      setUserExp(pllNation.exp)
    }
    let hasClaimed = getHasClaimed(pllNation?.lastDailyExpClaimed)
    setHasClaimedDailyXP(hasClaimed)
  }, [pllNation])

  useEffect(() => {
    // Set timer to clear copied so user can click multiple times
    if (showSnackbar) {
      const timer = setTimeout(() => {
        setShowSnackbar(false)
        setSnackbar({
          text: '',
          color: 'green',
        })
      }, 3000)
      return () => clearTimeout(timer)
    }
    return
  }, [showSnackbar])

  const setupGamificationData = async (userId: string) => {
    if (!userId) {
      //Only fetch Config
      await retrieveConfig()
      setLoading(false)
      setIsAchievementsLoading(false)
      return
    }
    await Promise.all([
      retrieveConfig(),
      retrieveAchievements(uid),
      retrieveLeaderboardData(),
    ])
    return
  }

  const retrieveConfig = async () => {
    setIsAchievementsLoading(true)
    let config = await fetchGamificationConfig()
    if (!config?.gamificationConfig) return null
    setGamificationConfig(config.gamificationConfig)
    if (config?.gamificationConfig?.seasonStarts) {
      getSeasonAndWeek(config.gamificationConfig.seasonStarts)
    }
    setLoading(false)
    return config
  }

  const retrieveAchievements = async (userId: string | null) => {
    if (!userId) {
      console.log('No user id')
      return
    }
    let achList = await fetchAchievements(userId)
    if (!achList || !achList.achievements) return null
    setUserFeed(achList.feed)
    setAchievementsGroup(achList)
    setIsAchievementsLoading(false)
    return
  }

  const retrieveLeaderboardData = async () => {
    if (!pllNation) {
      setIsLeaderboardLoading(false)
      return
    }
    let feature = getFeatureFromConfig(
      'fanLeaderboard',
      gamificationConfig?.featureLevels
    )
    let isAboveLevel = getIsAboveLevel(pllNation.fanScore, feature)

    if (!isAboveLevel) {
      setIsLeaderboardLoading(false)
      return
    }

    let leaderboardList = await fetchLeaderboardData()
    if (!leaderboardList) {
      setIsLeaderboardLoading(false)
      return
    }
    setLeaderboardData(leaderboardList)
    setIsLeaderboardLoading(false)
    return
  }

  const handleClaimDailyXP = async () => {
    if (!pllNation) return Promise.resolve(null)

    setClaimLoading(true)
    const res = await claimDailyXP()

    if (res?.success) {
      setHasClaimedDailyXP(true)
      setClaimLoading(false)
      setSnackbar({
        text: 'Success! Code has been claimed.',
        color: 'green',
      })
      setShowSnackbar(true)
      logEvent(ANALYTICS_TAGS.daily_xp_claim, {
        streak: pllNation.dailyExpStreak,
        level: pllNation.fanScore,
        date: new Date(),
      })
    } else {
      setClaimLoading(false)
      setSnackbar({
        text: res?.error,
        color: 'red',
      })
      setShowSnackbar(true)
    }
    return res
  }

  const getSeasonAndWeek = (
    seasonsArr?: {
      season: number
      start: number
    }[]
  ) => {
    const currentTime = Math.floor(
      Date.parse(
        new Date().toLocaleDateString('en-US', {
          timeZone: 'America/New_York',
        })
      ) / 1000
    )
    const seasons = seasonsArr || PLLNSeasons
    let season = 1
    let seasonStartTime = seasons[0].start

    for (let i = 0; i < seasons.length; i++) {
      const current = seasons[i]
      if (seasons.length === i + 1 && currentTime >= current.start) {
        season = current.season
        seasonStartTime = current.start
        break
      } else if (
        currentTime >= current.start &&
        currentTime < seasons[i + 1].start
      ) {
        season = current.season
        seasonStartTime = current.start
        break
      }
    }

    const seasonWeek = weeksBetween(seasonStartTime, currentTime)
    setPLLNSeason(season)
    setPLLNWeek(seasonWeek)
  }

  const weeksBetween = (startDate: number, currentDate: number) => {
    if (currentDate < startDate) return 0
    return Math.floor(
      Math.ceil(currentDate - startDate) / (7 * 24 * 60 * 60)
    )
  }

  const updateGamificationUserFieldInState = (fieldName: keyof PLLNationUser, value: any) => {
    setUserPllNation({ ...userPllNation, [fieldName]: value })
  }

  const profileRowClick = (title: string) => {
    if (title.includes('Benefits')) {
      title = 'benefits'
    }
    logEvent(`profile_${title.toLowerCase()}_row_click`)
  }

  const handleUpdateUserExp = (exp: number) => {
    setUserExp(exp)
  }

  const getUserRewards = async (userId: string) => {
    if (!userId || !isLoggedIn) return
    let rewards = await fetchUserRewards(userId)
    if (rewards){
      let badges = rewards.badges.sort((a, b) => a.targetNumber - b.targetNumber)
      setUserBenefits(rewards.benefits)
      setUserBadges(badges)
    }
  }

  return (
    <GamificationContext.Provider
      value={{
        gamificationConfig,
        achievementsGroup,
        loading,
        isAchievementsLoading,
        hasClaimedDailyXP,
        claimLoading,
        userPllNation,
        leaderboardData,
        isLeaderboardLoading,
        userFeed,
        userExp,
        pllnWeek,
        pllnSeason,
        userBenefits,
        userBadges,
        getUserRewards,
        handleClaimDailyXP,
        handleUpdateUserExp,
        updateGamificationUserFieldInState,
        profileRowClick,
      }}
    >
      {children}
      {showSnackbar && snackbar && (
        <GeneralSnackbar
          show={showSnackbar}
          text={snackbar.text}
          color={snackbar.color}
        />
      )}
    </GamificationContext.Provider>
  )
}
