import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import { initFirebase } from './Api/firebase'
import { initializeSentry } from './Configs/Sentry/sentry'

declare global {
  interface Window {
    google: any
    ramp: any
    adsbygoogle: any
  }
}
initFirebase()
initializeSentry()

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(<App />)
