import { CheckCircle } from "@mui/icons-material"
import { colors } from "../consts"
import { styled } from '@mui/system'

const CheckedCircleCon = styled(CheckCircle)<{iconColor?: string}>(
    ({iconColor}) => ({
    color: iconColor || colors.green.green,
    height: 20,
  }))

type CheckedCircleProps = {
    iconColor?: string
}

export const CheckedCircle: React.FC<CheckedCircleProps> = ({iconColor}) => {
    return (
        <CheckedCircleCon iconColor={iconColor}/>
    )
}