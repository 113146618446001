import React, {
  createContext,
  useState,
  useEffect,
  useContext,
} from 'react'
import { AuthContext } from '../../Api/firebase'
import { Question } from '../../Api'
import { getQuestionSet } from '../../Utils'
import { logEvent } from '../../Utils'
import { SeasonContext } from '../SeasonContext'
import { QuestionsContext } from '../QuestionsContext/QuestionsContext'

type PredictorContextType = {
  questionIndex: number
  predictorQuestions: Question[]
  handlePredictorSubmit: (
    question: Question,
    answerCode: string
  ) => Promise<boolean>
  updatePredictorQuestions: (wk: number) => Promise<Question[]>
  handlePredictorWeekChange: (week: number) => void
  goToNext: (questionIndex: number) => void
  goBack: (questionIndex: number) => void
  goToQuestion: (qIndex: number) => void
}
type PredictorProviderProps = { eventId?: string }
export type QuestionError = {
  [key: string]: {
    questionId: number
    display: boolean
    message: string
  }
} | null

export const PredictorContext =
  createContext<PredictorContextType | null>(null)

export const PredictorProvider: React.FC<
  React.PropsWithChildren<PredictorProviderProps>
> = ({ children }) => {
  const { uid, isLoading } = React.useContext(AuthContext)!
  const { activeWeek, onWeekDropdownChange } =
    useContext(SeasonContext)!
  const { handleSubmitAnswer } = useContext(QuestionsContext)!
  const [questionIndex, setQuestionIndex] = useState<number>(0)
  const [predictorQuestions, setPredictorQuestions] = useState<
    Question[]
  >([])
  const [fetchingStatuses, setFetchingStatuses] = useState<{
    fetching: boolean
    loading: boolean
  }>({
    fetching: false,
    loading: true,
  })

  useEffect(() => {
    if (isLoading) return
    if (activeWeek !== null) {
      updatePredictorQuestions(activeWeek)
    }
  }, [uid])

  useEffect(() => {
    if (isLoading || fetchingStatuses.fetching) return
    if (activeWeek !== null) {
      updatePredictorQuestions(activeWeek)
    }
  }, [uid, activeWeek])

  const updatePredictorQuestions = async (wk: number) => {
    setFetchingStatuses({
      ...fetchingStatuses,
      fetching: true,
    })
    let qSet = await getQuestionSet(
      {
        qType: 'game-predictor',
        week: wk,
      },
      uid
    )
    setFetchingStatuses({
      ...fetchingStatuses,
      fetching: false,
    })
    if (qSet.length > 0) {
      getFirstUnansweredQuestion(qSet)
    }
    setPredictorQuestions(qSet)
    setFetchingStatuses({
      ...fetchingStatuses,
      loading: false,
    })
    return qSet
  }

  const goToQuestion = (qIndex: number) => {
    setQuestionIndex(qIndex)
  }

  const goToNext = (questionIndex: number) => {
    setQuestionIndex(questionIndex + 1)
  }

  const goBack = (questionIndex: number) => {
    setQuestionIndex(questionIndex - 1)
  }

  const handlePredictorWeekChange = (week: number) => {
    onWeekDropdownChange(week)
    logEvent('predictor_week_change_click', {
      week: week,
    })
  }

  const getFirstUnansweredQuestion = (questions: Question[]) => {
    if (questions.length < 1) {
      setQuestionIndex(0)
      return
    }
    let firstUnanswered = questions.findIndex((q) => !q.userAnswerCode)
    if (firstUnanswered === -1) {
      setQuestionIndex(questions.length) //Summary shows if all questions answered
      return
    }
    setQuestionIndex(firstUnanswered)
  }

  const handlePredictorSubmit = async (
    question: Question,
    answerCode: string
  ) => {
    let submitted = await handleSubmitAnswer(question, answerCode)
    if (submitted !== null) {
      const idx = predictorQuestions.findIndex(
        (q) => q.questionId === question.questionId
      )
      goToNext(idx)
      return true
    }
    return false
  }

  return (
    <PredictorContext.Provider
      value={{
        questionIndex,
        predictorQuestions,
        handlePredictorSubmit,
        updatePredictorQuestions,
        handlePredictorWeekChange,
        goToNext,
        goBack,
        goToQuestion,
      }}
    >
      {children}
    </PredictorContext.Provider>
  )
}
