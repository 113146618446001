import { Lock } from "@mui/icons-material"
import { colors } from "../consts"
import { styled } from '@mui/system'

const LockedCon = styled(Lock)<{iconColor?: string}>(
    ({iconColor}) => ({
    color: iconColor || colors.gray.gray20,
    height: 20,
  }))

type LockedProps = {
    iconColor?: string
}

export const Locked: React.FC<LockedProps> = ({iconColor}) => {
    return (
        <LockedCon iconColor={iconColor}/>
    )
}