import ErrorIcon from '@mui/icons-material/Error';

import { styled } from '@mui/material'
import { colors } from '../consts'

type ErrorProps = {
  iconColor?: string
  height?: number
  width?: number
}

const ErrorCon = styled(ErrorIcon)<ErrorProps>(
  ({ theme, iconColor, height, width }) => ({
    color: iconColor ?? colors.yellow,
    marginRight: theme.spacing(1),
    height: height ?? 30,
    width: width ?? 30
  })
)

export const Error: React.FC<ErrorProps> = ({
  iconColor,
  height,
}) => {
  return <ErrorCon iconColor={iconColor} height={height} />
}
