import { Cancel } from "@mui/icons-material"
import { colors } from "../consts"
import { styled } from '@mui/system'

const CancelCircleCon = styled(Cancel)<{iconColor?: string}>(
    ({iconColor}) => ({
    color: iconColor || colors.red,
    height: 20,
  }))

type CancelCircleProps = {
    iconColor?: string
}

export const CancelCircle: React.FC<CancelCircleProps> = ({iconColor}) => {
    return (
        <CancelCircleCon iconColor={iconColor}/>
    )
}