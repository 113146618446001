import { PeopleAlt } from '@mui/icons-material'
import { colors } from '../consts'
import { styled } from '@mui/system'

const PeopleCon = styled(PeopleAlt)<{
  iconColor?: string
  height?: number
}>(({ theme, iconColor, height }) => ({
  display: 'flex',
  alignItems: 'end',
  color: iconColor ?? colors.white,
  height: height ?? 20,
}))

type PeopleIconProps = {
  iconColor?: string
  height?: number
}

export const PeopleIcon: React.FC<PeopleIconProps> = ({
  iconColor,
  height,
}) => {
  return (
    <PeopleCon fontSize="large" iconColor={iconColor} height={height} />
  )
}
