import { Outlet } from 'react-router-dom'
import {
  CompetitionProvider,
  GamificationProvider,
  GamificationPublicProvider,
  PickemProvider,
} from '../../Context'
import { QuestionsProvider } from '../../Context/QuestionsContext/QuestionsContext'
import { PredictorProvider } from '../../Context/PredictorContext/PredictorContext'

export const GamificationContextLayout: React.FC = () => {
  return (
    <GamificationProvider>
      <GamificationPublicProvider>
        <CompetitionProvider>
          <QuestionsProvider>
            <PredictorProvider>
              <PickemProvider>
                <Outlet />
              </PickemProvider>
            </PredictorProvider>
          </QuestionsProvider>
        </CompetitionProvider>
      </GamificationPublicProvider>
    </GamificationProvider>
  )
}
