import React, {
  createContext,
  useState,
  useEffect,
} from 'react'
import { Event, fetchEventsByYear } from '../../Api'
import { SEASON } from '../../Configs/config'
import { getWeekList, logEvent } from '../../Utils'
import { findCurrentWeekWithDefaults } from '../../Utils/Season/seasonHelpers'
import { ANALYTICS_TAGS } from '../../Constants/analytics'

export type SeasonType = 'regular' | 'post' | 'allstar' | 'champseries'
export type Season = { seasonType: SeasonType; year: number }
type SeasonContextType = {
  activeWeek: number
  seasonWeek: number
  weekList: number[]
  events: Event[]
  isEventsLoading: boolean
  seasonSelect: Season
  onSeasonDropdownChange: (seasonOpts: Season) => void
  onWeekDropdownChange: (week: number) => void
}

export const SeasonContext = createContext<SeasonContextType | null>(
  null
)

export const SeasonProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const seasonWeek = findCurrentWeekWithDefaults(
    Math.floor(Date.now() / 1000)
  )
  const [activeWeek, setActiveWeek] = useState<number>(seasonWeek)
  const [weekList, setWeekList] = useState<number[]>([])
  const [events, setEvents] = useState<Event[]>([])
  const [isEventsLoading, setIsEventsLoading] = useState<boolean>(true)
  const [seasonSelect, setSeasonSelect] = useState<Season>({
    seasonType: 'regular',
    year: SEASON,
  })

  useEffect(() => {
    fetchEvents()
  }, [])

  const fetchEvents = async () => {
    let includeCS =
      seasonSelect.seasonType === 'champseries' ? true : false
    const evs = await fetchEventsByYear(seasonSelect.year, includeCS)
    setIsEventsLoading(false)
    let wkList = getWeekList(evs)
    setWeekList(wkList)
    if (evs.length > 0) {
      setEvents(evs)
    }
    return evs
  }

  const onSeasonDropdownChange = async (seasonOpts: Season) => {
    logEvent(ANALYTICS_TAGS.season_selection_change, {
      season_type: seasonOpts.seasonType,
      season_year: seasonOpts.year,
      previous_season_type: seasonSelect.seasonType,
    })
    setSeasonSelect(seasonOpts)
  }

  const onWeekDropdownChange = async (week: number) => {
    logEvent(ANALYTICS_TAGS.week_selection_change, {
      selected_week: week,
      previous_selected_week: activeWeek,
    })
    setActiveWeek(week)
  }

  return (
    <SeasonContext.Provider
      value={{
        activeWeek,
        seasonWeek,
        weekList,
        events,
        isEventsLoading,
        seasonSelect,
        onSeasonDropdownChange,
        onWeekDropdownChange,
      }}
    >
      {children}
    </SeasonContext.Provider>
  )
}
