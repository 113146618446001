import React from 'react'
import { MainRoutes } from './Routing'
import { AuthProvider } from './Api/firebase'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { NavbarProvider } from './Context/NavbarContext'
import { AdProvider, SnackbarProvider } from './Context'
import { LinkProvider } from './Context/LinkContext'
import { BrowserRouter } from 'react-router-dom'
import { checkIfEmbededInNativeApp, checkIfEmbededInPLLApp } from './Utils'

const App: React.FC = () => {

console.log('Is embedded in webview:', checkIfEmbededInNativeApp())
console.log('Is embedded in PLl App:', checkIfEmbededInPLLApp())

  return (
    <HelmetProvider>
      <Helmet>
        <title>Premier Lacrosse League Free To Play</title>
        <meta
          name="description"
          content={`Premier Lacrosse League's Fan Free To Plays. Play Pick'em, complete a bracket, earn prizes, and more.`}
        />
      </Helmet>
      <BrowserRouter>
        <LinkProvider>
          <NavbarProvider>
            <SnackbarProvider>
              <AdProvider>
                <AuthProvider allowAnonymous>
                  <MainRoutes />
                </AuthProvider>
              </AdProvider>
            </SnackbarProvider>
          </NavbarProvider>
        </LinkProvider>
      </BrowserRouter>
    </HelmetProvider>
  )
}

export default App
