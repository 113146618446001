import { useContext, useEffect, useState } from 'react'
import { FantasyGroup } from '../../../Api'
import { RightArrow } from '../../Buttons/styles'
import { colors } from '../../consts'
import { Img } from '../../Images/Img'
import { Desc, Head2 } from '../../Typography/Text.styles'
import { PLL_IMAGES } from '../../../Configs/config'
import { FantasyGroupContext } from '../../../Context'
import { CheckMarkIcon } from '../../Icons/CheckMarkIcon'
import { GroupModalItemCon, SelectedGroupCon } from './styles'

type GroupItemProps = {
  group?: FantasyGroup
  compId?: number
}

export const GroupModalItem: React.FC<GroupItemProps> = ({
  group,
  compId,
}) => {
  let { handleShowModal, joinCompetition } = useContext(
    FantasyGroupContext
  )!
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const itemTitle = group
    ? group?.name?.toUpperCase()
    : 'CREATE NEW GROUP'
  let groupJoinedComp = group?.competitions?.some(
    (comp) => comp.competitionId === compId
  )

  useEffect(() => {
    getIsSelected()
  }, [])

  useEffect(() => {
    if (isSelected && !groupJoinedComp) {
      joinGroupToCompetition()
    }
  }, [isSelected])

  const getIsSelected = () => {
    let foundComp = group?.competitions?.find(
      (g) => g.competitionId === compId
    )
    setIsSelected(foundComp ? true : false)
  }

  const joinGroupToCompetition = async () => {
    let joined = await joinCompetition(compId, group?.id)
    console.log(joined)
    if (joined) {
      console.log('joined')
      return
    }
    console.log('not joined')
    setIsSelected(false)
  }
  console.log(group)

  return (
    <GroupModalItemCon
      onClick={() => !group && handleShowModal('createGroup')}
    >
      <div className="itemLeft">
        {!group && <div className="dottedCircle">+</div>}
        {group && (
          <div className="imgCon">
            <Img
              key={group.id}
              src={group.groupImg ?? PLL_IMAGES.pllShield}
              alt={group.name}
            />
          </div>
        )}
        <div>
          <Head2 color={colors.white}>{itemTitle}</Head2>
          {group && (
            <Desc color={colors.gray.gray60}>
              {group.totalUsers} Users
            </Desc>
          )}
        </div>
      </div>
      <div>
        {group && (
          <SelectedGroupCon
            isSelected={isSelected}
            onClick={() => !isSelected && setIsSelected(!isSelected)}
          >
            {isSelected ? <CheckMarkIcon show={isSelected} /> : '+'}
          </SelectedGroupCon>
        )}
        {!group && (
          <RightArrow />
        )}
      </div>
    </GroupModalItemCon>
  )
}
