import React, {
  createContext,
  useState,
  useEffect,
  ChangeEventHandler,
} from 'react'
import { claimCode } from '../../Api/PLLNation/pllNation.api'
import { SnackbarData } from '../../Api/types/picks.types'
import { logEvent } from '../../Utils'
import { ANALYTICS_TAGS } from '../../Constants/analytics'
import { logFirEvent } from '../../Utils/analytics/firAnalytics'

type CodeClaimContextType = {
  codeInput: string
  handleCodeSubmit: (
    code?: string
  ) => Promise<{
    success: boolean
    status: number | null
    error: any
  }> | undefined
  handleCodeChange: ChangeEventHandler
  setCodeInput: Function
  showSnackbar: boolean
  snackbar: SnackbarData
}

export const CodeClaimContext =
  createContext<CodeClaimContextType | null>(null)

export const CodeClaimProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [codeInput, setCodeInput] = useState('')
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false)
  const [snackbar, setSnackbar] = useState<SnackbarData>({
    text: '',
    color: 'green',
  })

  useEffect(() => {
    // Set timer to clear copied so user can click multiple times
    if (showSnackbar) {
      const timer = setTimeout(() => {
        setShowSnackbar(false)
        setSnackbar({
          text: '',
          color: 'green',
        })
      }, 3000)
      return () => clearTimeout(timer)
    }
    return
  }, [showSnackbar])

  const handleCodeSubmit = async (code?: string) => {
    let useCode = code ? code : codeInput
    const res = await claimCode(useCode)
    logEvent(ANALYTICS_TAGS.code_claim_submit, {
      code: useCode
    })

    if (res?.success) {
      setSnackbar({
        text: 'Success! Code has been claimed.',
        color: 'green',
      })
      setShowSnackbar(true)
      logFirEvent(ANALYTICS_TAGS.code_claim_success, {
        code: useCode
      })
    } else {
      setSnackbar({
        text: res?.error,
        color: 'red',
      })
      setShowSnackbar(true)
      logFirEvent(ANALYTICS_TAGS.code_claim_failure, {
        code: useCode,
        error: res?.error
      })
    }
    setCodeInput('')
    return res
  }

  const handleCodeChange = (e) => {
    setCodeInput(e.target.value)
  }

  return (
    <CodeClaimContext.Provider
      value={{
        codeInput,
        handleCodeChange,
        handleCodeSubmit,
        setCodeInput,
        showSnackbar,
        snackbar,
      }}
    >
      {children}
    </CodeClaimContext.Provider>
  )
}
