import { useNavigate } from 'react-router-dom'
import { SingleFantasyGroupCompetition } from '../../../Api'
import { colors, fontSizes } from '../../consts'
import { RightArrow } from '../../Icons/RightArrow'
import { Desc, Head3 } from '../../Typography/Text.styles'
import { EnterGroupButton, PrivateCompIndicatorCon } from './styles'
import { FANTASY_GROUPS_COMPETITION_URL } from '../../../Routing/urls'
import { getTimeUntilString, logEvent } from '../../../Utils'
import { useContext, useEffect, useState } from 'react'
import { FantasyGroupContext } from '../../../Context'
import { Spinner } from '../../Icons'
import { ANALYTICS_TAGS } from '../../../Constants/analytics'
import { AuthContext } from '../../../Api/firebase'

type PrivateGroupIndicatorProps = {
  competition: SingleFantasyGroupCompetition
}

export const PrivateGroupCompIndicator: React.FC<
  PrivateGroupIndicatorProps
> = ({ competition }) => {
  const nav = useNavigate()
  let { uid } = useContext(AuthContext)!
  let { singleGroup, joinCompetition } = useContext(
    FantasyGroupContext
  )!
  const [groupHasJoined, setGroupHasJoined] = useState<boolean>(false)
  const [joinLoading, setJoinLoading] = useState<boolean>(false)

  useEffect(() => {
    let hasJoined = singleGroup?.competitions?.some(
      (comp) => comp.competitionId === competition.competitionId
    )
    setGroupHasJoined(hasJoined ?? false)
  }, [singleGroup?.competitions])

  const getSubText = () => {
    if (groupHasJoined) {
      const currentTime = Math.floor(Date.now() / 1000)
      return currentTime < competition.entryCloseTime
        ? `You're in! The competition locks ${getTimeUntilString(
            competition.entryCloseTime, false
          )}.`
        : `Rank ${competition.groupRank ?? '-'}/${competition.totalGroupCount}`
    }
    return `Compete against all groups for custom prizes. Enter Now!`
  }

  const getActionClick = async () => {
    logEvent(ANALYTICS_TAGS.fantasy_competition_callout_click, {
      competition_id: competition.competitionId,
      competition_name: competition.name,
      location: 'single_group',
      button_text: groupHasJoined ? 'Enter Group' : 'Right Arrow',
    })
    if (groupHasJoined && competition) {
      return nav(
        `${FANTASY_GROUPS_COMPETITION_URL}/${competition?.competitionId}`
      )
    }
    return joinGroupToCompetition()
  }

  const joinGroupToCompetition = async () => {
    setJoinLoading(true)
    let joined = await joinCompetition(
      competition.competitionId,
      singleGroup?.id
    )
    if (joined) {
      logEvent(ANALYTICS_TAGS.fantasy_competition_enter_group_click, {
        group_id: singleGroup?.id,
        is_group_owner: singleGroup?.groupOwner === uid,
        num_members: singleGroup?.totalUsers,
        members: singleGroup?.leaderboard,
        location: 'single_group',
      })
      setGroupHasJoined(true)
      setJoinLoading(false)
      return
    }
    setJoinLoading(false)
    console.log('not joined')
  }

  return (
    <PrivateCompIndicatorCon>
      <div className="top">
        <Head3 color={colors.white}>{competition?.name}</Head3>
        <div onClick={getActionClick}>
          {groupHasJoined && <RightArrow />}
          {!groupHasJoined && !joinLoading && (
            <EnterGroupButton>
              <Head3 color={colors.black} vertMargins={1}>
                Enter Group
              </Head3>
            </EnterGroupButton>
          )}
          {!groupHasJoined && joinLoading && (
            <Spinner color={colors.yellow} width={20} />
          )}
        </div>
      </div>
      <div className="bottom">
        <Desc color={colors.gray.gray40} fontSize={fontSizes.small}>
          {getSubText()}
        </Desc>
      </div>
    </PrivateCompIndicatorCon>
  )
}
