import { Outlet } from 'react-router-dom'
import {
  GamificationPublicProvider,
  PlayerStarsProvider,
} from '../../Context'

export const PlayerStarsContextLayout: React.FC = () => {
  return (
    <PlayerStarsProvider>
      <GamificationPublicProvider>
        <Outlet />
      </GamificationPublicProvider>
    </PlayerStarsProvider>
  )
}
