import React, { useContext } from 'react'
import { styled } from '@mui/system'
import { colors } from '../consts'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { AuthContext } from '../../Api/firebase'
import { Btn } from './Btn'

type SubmitButtonProps = {
  disabled: boolean
  requireAuth: boolean
  handleSubmit?: (...args: any[]) => any | Promise<any>
  analytics?: {
    event: string
    params?: unknown
  }
}

type SubmitStyleProps = {
  disabled: boolean
}
export const StyledRightArrow = styled(KeyboardArrowRightIcon)<SubmitStyleProps>(({ disabled }) => ({
  color: disabled ? colors.gray.gray90 : colors.black,
  fontSize: '1.8rem'
}))

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  disabled,
  requireAuth = false,
  handleSubmit,
  analytics
}) => {
  let { login, isLoggedIn } = useContext(AuthContext)!

  const onSubmit = () => {
    if (requireAuth === true && !isLoggedIn) {
      return login()
    }
    handleSubmit && handleSubmit()
  }

  return (
    <Btn
      btnStyle='primary'
      disabled={disabled}
      handleClick={onSubmit}
      analytics={analytics}
    >
      <StyledRightArrow disabled  />
    </Btn>
  )
}
