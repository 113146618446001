import branch from 'branch-sdk'

export const initializeBranch = async () => {
    if (!process.env.REACT_APP_BRANCH_KEY) return false
    branch.init(process.env.REACT_APP_BRANCH_KEY, {}, (err, data) => {
      if (err) {
        console.error(err)
        return false
      }
      return true
    })
    return Promise.resolve(true)
}