import React from 'react'
import { PLL_IMAGES } from '../../../Configs/config'
import { Img } from '../../Images/Img'
import { Desc } from '../../Typography/Text.styles'
import { colors } from '../../consts'

type FantasyPrizeProps = {
  img?: string
  desc?: string
  title?: string
  headerTitle?: string
}

export const PrizeItem: React.FC<
  React.PropsWithChildren<FantasyPrizeProps>
> = ({ img, desc, title, headerTitle = 'Prize' }) => {
  const itemizeDesc = () => {
    if (!desc) return
    return desc.replace(/- \s*/g, `\n- `)
  }

  return (
    <>
      {img && (
        <div className="imgCon">
          <Img src={img || PLL_IMAGES.pllShield} alt={title} />
        </div>
      )}
      <h4 className="prizeTitle">{title}</h4>
      {desc && (
        <Desc
          color={colors.gray.gray40}
          style={{ whiteSpace: 'pre-wrap' }}
          className="desc"
        >
          {itemizeDesc()}
        </Desc>
      )}
    </>
  )
}
