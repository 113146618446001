export const MAIN_PLL_URL = 'premierlacrosseleague.com'
export const MAIN_PLL_HOME = `https://${MAIN_PLL_URL}`
export const API_ROOT_URL = `${process.env.REACT_APP_API_BASE_ROUTE}/api`
export const PLL_IMAGE_URL = `https://${MAIN_PLL_URL}/wp-content/uploads`
export const BROADCAST_IMG = `https://img.${MAIN_PLL_URL}/Logos`
export const REVERSE_BROADCAST_IMG = `https://img.${MAIN_PLL_URL}/Logos`
export const Team_LOGO_URL = `https://img.${MAIN_PLL_URL}/Teams`
export const ADD_EVENT_SCRIPT =
  'https://addevent.com/libs/atc/1.6.1/atc.min.js'
export const TWITTER_SCRIPT = 'https://platform.twitter.com/widgets.js'
export const GRAPHQL_ROOT_URL =
  process.env.REACT_APP_GRAPH_ROOT_URL ||
  'http://localhost:5000/graphql'
export const SEASON = 2024
export const CURRENT_WEEK = 12
export const ALL_STAR_WEEK = 6
export const GRAPHQL_ACCESS_KEY =
  process.env.REACT_APP_GRAPHQL_AUTH_KEY || ''
export const SHARE_TITLE = `Play The Cash App Challenge | PLL's 2022 Bracket Challenge`
export const FREE_TO_PLAY_URL =
  process.env.NODE_ENV === 'development'
    ? 'https://staging-f2p.premierlacrosseleague.com/'
    : 'https://f2p.premierlacrosseleague.com/'
export const BRANCH_BASE_URL =
  process.env.REACT_APP_BRANCH_BASE_URL || 'https://pll.gg'

export const PLL_LINKS = {
  login: `/login`,
  schedule: `https://${MAIN_PLL_URL}/schedule`,
  championShipSeries: `https://${MAIN_PLL_URL}/championship-series`,
  shop: `https://shop.${MAIN_PLL_URL}`,
  stats: `https://stats.${MAIN_PLL_URL}`,
  academy: `https://${MAIN_PLL_URL}/academy`,
  assists: `https://${MAIN_PLL_URL}/pll-assists/`,
  media: `https://${MAIN_PLL_URL}/media/`,
  draftCentral: `https://${MAIN_PLL_URL}/draft-central/`,
  transactions: `https://${MAIN_PLL_URL}/transactions/`,
  careers: `https://${MAIN_PLL_URL}/careers/`,
  peacock:
    'https://www.peacocktv.com/sports/premier-lacrosse-league?cid=2101sportssymedi1642&utm_campaign=2101sports&utm_source=premierelacrosseleague&utm_medium=sym_editorial_awa_postedlink&utm_term=lacrossefans&utm_content=lacrosse',
  watchLink: 'https://premierlacrosseleague.com/where-to-watch',
  matchups: `https://${MAIN_PLL_URL}/matchup`,
  bet: `https://${MAIN_PLL_URL}/bet`,
  standings: `https://${MAIN_PLL_URL}/standings`,
  tickets: `https://${MAIN_PLL_URL}/tickets`,
  injuries: `https://premierlacrosseleague.com/injury-report`,
  fantasy: `/fantasy`,
  fantasyScoring: `https://${MAIN_PLL_URL}/fantasy#royale-scoring`,
  fantasyHowToPlay: `https://${MAIN_PLL_URL}/fantasy/how-to-play`,
  bracket: `/bracket`,
}

export const PLL_IMAGES = {
  pllShield: `${PLL_IMAGE_URL}/2019/03/pll_logo.png`,
  main_banner: `${PLL_IMAGE_URL}/2021/05/PLL_Free2Play_banner.jpg`,
  bracketBanner: `${PLL_IMAGE_URL}/2023/08/cash-app-challenge-logo.webp`,
  teamChampion: `${PLL_IMAGE_URL}/2021/08/BracketChallenge_`,
  cashAppChallengeLogo: `https://${MAIN_PLL_URL}/wp-content/uploads/2023/08/2023_CashAppChallengeLogo.webp`,
  playoffLogo: `https://${MAIN_PLL_URL}/2022_playoff_logo`,
  productBanner: `${PLL_IMAGE_URL}/2022/08/cash-app-challenge-web-banner-scaled.jpg`,
  cashAppBonusLogo: `${PLL_IMAGE_URL}/2022/08/5KBonusPoolLogo.png`,
  cashAppShare: `https://${MAIN_PLL_URL}/cash-app-challenge-featured`,
  bracketBackground: `https://${MAIN_PLL_URL}/2022_pllbracket_bg_final`,
  pllAppLogo: `https://img.${MAIN_PLL_URL}/Teams/2022/Logo/pll-app-icon.jpg`,
  pickemLogo: `https://img.${MAIN_PLL_URL}/Logos/2023/pickem-cs-logo.png`,
  pllAppFeaturedImage: `https://img.${MAIN_PLL_URL}/2022/04/AppFormstack-scaled.jpg`,
  fantasyShare: `${PLL_IMAGE_URL}/2023/05/IMG_7199-scaled.webp`,
  defaultProfile: `${PLL_IMAGE_URL}/2024/05/Headshot_generic_nobackground.webp`,
  weeklyFantasyLogo: `${PLL_IMAGE_URL}/2023/05/WF-stackedwhite.png`,
  weeklyFantasyBanner: `${PLL_IMAGE_URL}/2023/07/wk-fantasy-banner.webp`,
  defaultLockedBenefit: `https://img.${MAIN_PLL_URL}/2023/locked-benefit.png`,
  defaultUnlockedBenefit: `https://img.${MAIN_PLL_URL}/2023/unlocked-benefit.png`,
  pllNationLogo: `${PLL_IMAGE_URL}/2024/01/PLL_Nation_Logo_white.webp`,
  yellow_trophy: `https://img.${MAIN_PLL_URL}/2024/trophy_ic_yellow.png`,
}

type SortDirectionType = {
  [key: string]: -1 | 1
}
export const SORTING_DIRECTIONS: SortDirectionType = {
  ASC: 1,
  DESC: -1,
}

//BRACKET OBJECTS
export const championshipEventIds = [
  {
    year: 2022,
    eventId: 124,
  },
  {
    year: 2023,
    eventId: 228,
  },
]

//ACTUAL EVENT IDS
export const playoffEventIds = {
  //QUARTERS
  q1: 223,
  q2: 224,
  q3: 225,
  //SEMIS
  s1: 226,
  s2: 227,
  //CHAMP
  c: 228,
}

export const PRIZE_BANNERS = {}
export type PrizeKeys = keyof Prizes
export type Prizes = {
  [key: string]: string
}
export const PRIZES: Prizes = {}

//
// firebase config
// https://firebase.google.com/docs/web/setup
//
const API_KEY = process.env.REACT_APP_FIREBASE_API_KEY || ''
const PROJECT_ID = process.env.REACT_APP_PROJECT_ID || ''
const SENDER_ID = process.env.REACT_APP_SENDER_ID || ''
const APP_ID = process.env.REACT_APP_ID || ''
const MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID || ''
const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN || ''
export const GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID || ''

export const FIREBASE_CONFIG = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  databaseURL: `https://${PROJECT_ID}.firebaseio.com`,
  projectId: PROJECT_ID,
  storageBucket: `${PROJECT_ID}.appspot.com`,
  messagingSenderId: SENDER_ID,
  appId: APP_ID,
  measurementId: `G-${MEASUREMENT_ID}`,
}

export const USE_FIREBASE = API_KEY.length > 0

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
]
export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const dailyAchievementDays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export const shortDays = [
  'Sun',
  'Mon',
  'Tues',
  'Wed',
  'Thurs',
  'Fri',
  'Sat',
]

export const TEAM_MENU = [
  {
    linkText: 'Archers',
    link: `${MAIN_PLL_HOME}/teams/archers`,
  },
  {
    linkText: 'Atlas',
    link: `${MAIN_PLL_HOME}/teams/atlas`,
  },
  {
    linkText: 'Cannons',
    link: `${MAIN_PLL_HOME}/teams/cannons`,
  },
  {
    linkText: 'Chaos',
    link: `${MAIN_PLL_HOME}/teams/chaos`,
  },
  {
    linkText: 'Chrome',
    link: `${MAIN_PLL_HOME}/teams/chrome`,
  },
  {
    linkText: 'Redwoods',
    link: `${MAIN_PLL_HOME}/teams/redwoods`,
  },
  {
    linkText: 'Waterdogs',
    link: `${MAIN_PLL_HOME}/teams/waterdogs`,
  },
  {
    linkText: 'Whipsnakes',
    link: `${MAIN_PLL_HOME}/teams/whipsnakes`,
  },
]
export const MORE_MENU = [
  {
    linkText: 'Assists',
    link: PLL_LINKS.assists,
  },
  {
    linkText: 'Injuries',
    link: PLL_LINKS.injuries,
  },
  {
    linkText: 'Transactions',
    link: PLL_LINKS.transactions,
  },
  {
    linkText: 'Draft',
    link: PLL_LINKS.draftCentral,
  },
  {
    linkText: 'Careers',
    link: PLL_LINKS.careers,
  },
]

export type Offering = {
  title: string
  imgUrl: string
  desc: string
  btnText: string
  link: string
}

export const PRODUCT_OFFERINGS = [
  {
    title: `Pick'em`,
    imgUrl:
      'https://premierlacrosseleague.com/wp-content/uploads/2023/02/App-Background-Image-2-1.png',
    desc: 'Pick the winners of upcoming matchups.',
    btnText: 'Set Picks',
    link: '/pickem',
  },
  {
    title: 'Predictor',
    imgUrl:
      'https://premierlacrosseleague.com/wp-content/uploads/2023/02/App-Background-Image-3-1.png',
    desc: 'Test your league knowledge with weekly game props.',
    btnText: 'Lock In Predictions',
    link: '/predictor',
  },
]

export type WeekObj = {
  week: number
  openTime: number
  closeTime: number
}

//Easier way to know when fantasy week starts and ends
export const CURRENT_SEASON_WEEKS: WeekObj[] = [
  {
    week: 0,
    openTime: 1703793793,
    closeTime: 1713999733,
  },
  {
    week: 1,
    openTime: 1713999734,
    closeTime: 1717444311,
  },
  {
    week: 2,
    openTime: 1717444312,
    closeTime: 1718089200,
  },
  {
    week: 3,
    openTime: 1718089201,
    closeTime: 1718694000,
  },
  {
    week: 4,
    openTime: 1718694001,
    closeTime: 1719903600,
  },
  {
    week: 5,
    openTime: 1719903601,
    closeTime: 1720508400,
  },
  {
    week: 6,
    openTime: 1720508401,
    closeTime: 1721113200,
  },
  {
    week: 7,
    openTime: 1721113201,
    closeTime: 1721718000,
  },
  {
    week: 8,
    openTime: 1721718001,
    closeTime: 1722322800,
  },
  {
    week: 9,
    openTime: 1722322801,
    closeTime: 1722927600,
  },
  {
    week: 10,
    openTime: 1722927601,
    closeTime: 1723532400,
  },
  {
    week: 11,
    openTime: 1692687601,
    closeTime: 1724140844,
  },
  {
    week: 12,
    openTime: 1724140845,
    closeTime: 1725346800,
  },
  {
    week: 13,
    openTime: 1724742001,
    closeTime: 1725951600,
  },
  {
    week: 14,
    openTime: 1725346801,
    closeTime: 1726556400,
  }
]
